<template>
    <div class="row grow w-100">
        <div class="col-1">
            <NavSidebar />
        </div>
        <div class="main col-11 h-100 py-5 padding-subpages">
            <b-card style="border: none">
                <b-card-title class="px-3 py-3">Impressum</b-card-title>
                <b-card-body>
                    <p>ELSI-SAT Health & Care (H&C) ist ein vom Bundesministerium für Bildung und Forschung (BMBF) gefördertes
                    Projekt, das im Zeitraum Oktober 2021 bis Juni 2023 an der Hochschule der Medien Stuttgart (HdM) vom
                    Institut für digitale Ethik (IDE) zusammen mit dem Center for Life Ethics der Universität Bonn
                    konzipiert und umgesetzt wurde.</p>

                    <div class="d-flex flex-column">
                        <h5>Inhaltlich verantwortliche Stelle</h5>
                        <p>Hochschule der Medien<br>
                            Nobelstr. 10<br>
                            70569 Stuttgart<br>
                            Tel.: 0711/ 8923 – 10<br>
                            Fax: 0711/ 8923 – 11 <br>
                            <a href="https://www.hdm-stuttgart.de">www.hdm-stuttgart.de</a>
                        </p>
                    </div>

                    <div class="d-flex flex-column">
                        <h5>Zuständige Rechtsaufsichtsbehörde</h5>
                        <p>Ministerium für Wissenschaft, Forschung und Kunst Baden-Württemberg (MWK)
                            Königstraße 46<br>
                            70173 Stuttgart<br>
                            Tel.: 0711/ 279 – 0<br>
                            Fax: 0711/ 279 – 3080<br>
                            <a href="https://www.mwk.bwl.de">www.mwk.bwl.de</a><br>
                            <a href="poststelle@mwk.bwl.de">poststelle@mwk.bwl.de</a>
                        </p>
                    </div>

                    <h5>Forschungsleitende Projektverantwortung</h5>
                    <div class="d-flex flex-column">
                        <p>Institut für Digitale Ethik (IDE)<br>
                            Prof. Dr. Tobias Keber / Prof. Dr. Petra Grimm<br>
                            Tel.: 0711/ 8923 – 2202<br>
                            <a
                                href="https://www.hdm-stuttgart.de/digitale-ethik/home">www.hdm-stuttgart.de/digitale-ethik/home</a><br>
                            <a href="mailto:ide@hdm-stuttgart.de">ide@hdm-stuttgart.de</a><br>
                        </p>
                        <p>
                            Inhaltliche Fragen oder Anregungen zum Tool können auch an die folgende E-Mail-Adressen
                            gerichtet werden::<br>
                            <a href="mailto:elsisat@hdm-stuttgart.de">elsisat@hdm-stuttgart.de</a> bzw.
                            <a href="mailto:elsi-sat@uni-bonn.de">elsi-sat@uni-bonn.de</a>
                        </p>
                    </div>

                    <div>
                        <h4 style='margin-top:40px;margin-bottom:10px'>Allgemeine Hinweise</h4>
                        <div>
                            <h5>1. Disclaimer (Haftungsausschluss)</h5>
                            <p>Die Autoren und Verantwortlichen übernehmen keine Gewähr für die Aktualität, Korrektheit oder
                                Vollständigkeit der bereitgestellten Informationen. Die Haftung für Schäden materieller oder
                                immaterieller Art, welche aufgrund einer Nutzung der Informationen entstehen können, wird
                                ausgeschlossen, sofern kein vorsätzliches oder grob fahrlässiges Verhalten vorliegt.
                            </p>
                            <p>
                                Autoren und Verantwortliche behalten sich vor, einzelne Seiten oder das gesamte Angebot ohne
                                Ankündigung zu verändern, zu ergänzen, zu löschen und zeitweise oder endgültig einzustellen.
                                Das Tool ELSI-SAT H&C sowie die entsprechende Webseite weisen (sofern im Text nicht anders
                                angegeben) projektlaufzeitbedingt den Bearbeitungs-, Gesetzes- und Sachstand Juni 2023 auf.
                                Nach diesem Zeitpunkt wurden Tool und Webseite auch aufgrund geltender Fördermodalitäten
                                nicht aktualisiert oder auf Aktualität überprüft.
                            </p>
                            <p>
                                Sämtliche Darstellungen und Beschreibungen von Werten, Begriffen, Fallbeispielen oder
                                gesetzlichen Grundlagen sind format- und übersichtlichkeitsbedingt möglichst kurz und
                                prägnant gehalten und können nicht sämtliche Aspekte oder Facetten der Thematik abbilden.
                                Das Glossar dient lediglich der Erläuterung ausgewählter Begrifflichkeiten zum Zweck der
                                besseren Orientierung im inhaltlichen Bereich des Tools und erhebt weder Anspruch auf
                                Allgemeingültigkeit noch auf Aktualität oder Vollständigkeit.
                            </p>
                        </div>
                        <div>
                            <h5>2. Verweise und Links</h5>
                            <p>Das Tool nutzt an verschiedenen Stellen die technischen Möglichkeiten der Verlinkung. Im Fall
                                von direkten oder indirekten Links auf fremde Inhalte kommt eine Haftung nur dann in
                                Betracht, wenn positive Kenntnis der Verantwortlichen hinsichtlich angeblich rechtswidriger
                                Inhalte vorliegt und es technisch, faktisch und zeitlich möglich und zumutbar gewesen wäre,
                                deren Veröffentlichung zu verhindern. Bei erstmaliger Verknüpfung wurden fremde Inhalte
                                daraufhin überprüft, ob die Einbindung eine rechtliche Verantwortlichkeit auslösen könnte.
                                Inhalte, auf die dieses Angebot verweist, können jedoch nicht ständig auf Veränderungen, die
                                evtl. eine Verantwortlichkeit begründen können, überprüft werden (Stand 6/2023, s.o. Ziff.
                                1).
                            </p>
                        </div>
                        <div>
                            <h5>3. Urheberrecht</h5>
                            <p>Inhalte und Strukturen des Angebots sind zwar als Open-Access-Anwendung ausgestaltet und
                                somit öffentlich verfügbar. Trotzdem besteht ein urheber- bzw. leistungsschutzrechtlicher
                                Vorbehalt.
                            </p>
                            <p>
                                Die Veröffentlichung von Webseiten im „World Wide Web“ oder sonstigen Diensten des Internet
                                ist keine Einverständniserklärung für eine andere als die vom Anbieter vorgesehene Nutzung
                                (hier: Inhaltliche Bearbeitung, Download, Export an Projektbeteiligte, (digitale)
                                Einreichung mit Förderantrag etc.). Jede nicht urheberseitig intendierte oder nach deutschem
                                Urheberrecht zulässige Verwertung bedarf einer schriftlichen Zustimmung der Autoren bzw.
                                Verantwortlichen.
                            </p>
                            <p>
                                Wir erlauben und begrüßen jedoch ausdrücklich das Zitieren unserer Dokumente sowie das
                                Setzen von Links oder Hinweisen auf unser Angebot, solange dabei kenntlich gemacht wird,
                                dass es sich um Inhalte der vorgenannten Verantwortlichen handelt und betreffende Inhalte
                                nicht in Verbindung mit Inhalten Dritter gebracht werden, die jenen Interessen
                                widersprechen.
                            </p>
                        </div>
                        <div>
                            <h5>4. Grafikdesign</h5>
                            <p>Die Grafiken auf der Startseite sowie in den ELSI-Karten wurden von Michelle Samuels und
                                Melissa Saintier (beide HdM) entworfen und umgesetzt. Die Grafiken in der Projektübersicht
                                wurden von Patrizia Schiffrer konzipiert und erstellt. Die Icons stammen von der Open
                                Access-Anwendung "Feather" (<a href="https://feathericons.com">feathericons.com)</a>.
                            </p>
                        </div>
                        <div>
                            <h5>5. Genderhinweis</h5>
                            <p>Zur Vermeidung subjektiver Benachteiligungen wurde in den Texten des Tools sowie auf dieser
                                Webseite grundsätzlich auf die gleichzeitige Ansprache männlicher und weiblicher Nutzer bzw.
                                Nutzerinnen geachtet. Dies geschah zur besseren Lesbarkeit und Verständlichkeit, wenn
                                möglich durch die Wahl neutraler Sprachformen (z.B. Gerundium), ansonsten auch durch das
                                Einfügen entsprechend genutzter Zeichen ("Sternchen").</p>
                        </div>
                    </div>
                </b-card-body>
            </b-card>
        </div>
    </div>
</template>

<script>

import NavHeader from "../components/Nav_Header.vue"
import NavSidebar from "../components/Nav_Sidebar.vue"
export default {
    name: 'impressum',
    components: {
        NavHeader,
        NavSidebar,
    },

    data() {
        return {

        }
    }
}

</script>

<style lang="scss" scoped></style>
